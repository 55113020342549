// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import {LIME_4, MIDNIGHT_BLUE, LIME_2, LIME_1} from "../../styles/colors";
import {BREAKPOINTS} from "../../styles/sizes";

type errorType = {
  message: string,
};

type Props = {
  processing: boolean,
  error: ?errorType,
  disabled: boolean,
  children: React.Node,
};

const Field = (props: Props): React.Node => {
  const {processing, error, disabled, children} = props;
  const intl = useIntl();

  return (
    <button
      className={`${css(styles.submitButton)} ${
        error ? css(styles.submitButtonError) : ""
      }`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing
        ? intl.formatMessage({id: "donate_now_form_processing"})
        : children}
    </button>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    display: "block",
    zIndex: "2",
    fontSize: "16px",

    height: "40px",
    backgroundColor: LIME_4,
    // boxShadow: "0px 5px 7px 0px rgb(0 0 20 / 20%)",
    borderRadius: "4px",
    color: MIDNIGHT_BLUE,
    fontWeight: "600",
    cursor: "pointer",
    transition: "all 100ms ease-in-out",
    willChange: "transform, background-color, box-shadow",
    appearance: "none",
    outline: "none",
    borderStyle: "none",

    margin: "15px 0px 0px",
    width: "100%",
    ":active": {
      backgroundColor: LIME_2,
      transform: "scale(0.99)",
    },
    ":hover": {
      backgroundColor: LIME_2,
      transform: "scale(0.99)",
    },
    ":disabled": {
      opacity: "0.5",
      cursor: "default",
      backgroundColor: LIME_1,
      boxShadow: "none",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "calc(100% - 30px)",
      margin: "15px 15px 0",
    },
  },
  submitButtonError: {
    transform: "translateY(10px)",
  },
});

export default Field;
