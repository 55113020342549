// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {GRAY, MID_GREY, BLACK} from "../../styles/colors";

type Props = {
  label: string,
  id: string,
  type: string,
  placeholder: string,
  required?: boolean,
  autoComplete?: string,
  value: ?(string | number),
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  labelStyle?: any,
  inputStyle?: any,
};

const Field = (props: Props): React.Node => {
  const {
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,
    labelStyle,
    inputStyle,
  } = props;

  return (
    <div className={css(styles.formField)}>
      <label htmlFor={id} className={css(styles.formRowLabel, labelStyle)}>
        {label}
      </label>
      <input
        className={css(styles.formRowInput, inputStyle)}
        id={id}
        type={type}
        name={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  formField: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    fontSmoothing: "antialiased",
  },
  formRowLabel: {
    width: "15%",
    minWidth: "70px",
    padding: "11px 0",
    color: GRAY,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "16px",
  },
  formRowInput: {
    fontSize: "16px",
    width: "85%",
    padding: "11px 15px 9px 0px",
    color: BLACK,
    backgroundColor: "transparent",
    animation: "1ms void-animation-out",
    appearance: "none",
    outline: "none",
    borderStyle: "none",
    "::placeholder": {
      color: GRAY,
    },
    "::-webkit-input-placeholder": {
      fontSize: "16px",
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
  },
});

export default Field;
