// @flow strict

export const parseNumber = (numberString: string): number => {
  const num = Number.parseFloat(numberString.replace(/,/g, ""));
  // toFixed will return string, the following will return a number type value instead
  return Math.round(num * 1e2) / 1e2;
};

export const formatNumberStringToDollar = (number: string): string => {
  return `$${String(parseNumber(number)).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  )}`;
};
