// @flow
import React, {type Element} from "react";
import {graphql} from "gatsby";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {css, StyleSheet} from "aphrodite";

import {type ContentulPageQueryWithStripe} from "../types/__generated__/ContentulPageQueryWithStripe";
import Layout from "../container/Layout";
import Sections from "../components/v2/Sections.jsx";
import DonationForm from "../components/DonationForm.jsx";
import {WHITE} from "../styles/colors";
import {BREAKPOINTS} from "../styles/sizes";

const stripePromise = loadStripe(
  process.env.GATSBY_REACT_APP_STRIPE_PUBLIC_KEY
);

type PageProps = {|
  +data: ContentulPageQueryWithStripe,
|};

const PageWithStripe = ({data}: PageProps): Element<any> => {
  const {contentfulPage} = data;
  const bodyRight: React$Element<"div"> = (
    <div className={css(styles.stripe)}>
      <Elements stripe={stripePromise}>
        <DonationForm />
      </Elements>
    </div>
  );

  return (
    <Layout
      slug={contentfulPage?.slug}
      showAnnouncementBanner={contentfulPage?.showAnnouncementBanner}
      headerInfo={contentfulPage?.headerInfo}
    >
      <Sections
        sections={contentfulPage?.sections || []}
        bodyRight={bodyRight}
      />
    </Layout>
  );
};

export default PageWithStripe;

const styles = StyleSheet.create({
  stripe: {
    padding: "10px 20px 20px 20px",
    backgroundColor: WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      width: "500px",
      margin: "16px 0",
    },
  },
});

export const query: any = graphql`
  query ContentulPageQueryWithStripe($slug: String!, $locale: String!) {
    contentfulPage(
      slug: {eq: $slug}
      node_locale: {eq: $locale}
      templateName: {eq: "PageWithStripe"}
    ) {
      __typename
      internalTitle
      slug
      navigationName
      isReadyForProduction
      showAnnouncementBanner
      headerInfo {
        ...ContentfulHeaderInfoFragment
      }
      sections {
        __typename
        ... on ContentfulHero {
          ...ContentfulHeroFragment
        }
        ... on ContentfulMultiColumn {
          ...ContentfulMultiColumnFragment
        }
        ... on ContentfulPartners {
          ...ContentfulPartnersFragment
        }
        ... on ContentfulQuote {
          ...ContentfulQuoteFragment
        }
        ... on ContentfulSplitModule {
          ...ContentfulSplitModuleFragment
        }
        ... on ContentfulHeadlineFullBleedImageStack {
          ...ContentfulHeadlineFullBleedImageStackFragment
        }
      }
    }
  }
`;
