// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import {COUNTRY_OPTIONS} from "../../constants";
import dropdownImg from "../../assets/dropdown.svg";
import {GRAY, MID_GREY, BLACK} from "../../styles/colors";
import {BREAKPOINTS} from "../../styles/sizes";

type Props = {
  value: ?(string | number),
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
};

const CountryField = (props: Props): React.Node => {
  const {value, onChange} = props;
  const intl = useIntl();

  return (
    <>
      <label htmlFor="country" className={css(styles.formRowLabel)}>
        {intl.formatMessage({id: "donate_now_form_country"})}
      </label>
      <div id="country" className={css(styles.country)}>
        <select
          name="country"
          className={css(styles.select)}
          value={value}
          onChange={onChange}
          required
          autoComplete="country"
        >
          <option value="" disabled>
            {intl.formatMessage({id: "donate_now_form_country_select"})}
          </option>
          {COUNTRY_OPTIONS.map(({value: countryCode, label}) => (
            <option key={countryCode} value={countryCode}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  formRowLabel: {
    width: "15%",
    minWidth: "70px",
    padding: "11px 0",
    color: GRAY,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "16px",
  },
  country: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    background: "transparent",
    outline: "none",
    position: "relative",
    width: "calc(100vw - 140px)",
    "::after": {
      content: '""',
      position: "absolute",
      width: "9px",
      height: "5px",
      right: "20px",
      top: "50%",
      marginTop: "-2px",
      backgroundImage: `url(${dropdownImg})`,
      pointerEvents: "none",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "100%",
    },
  },
  select: {
    flex: "1",
    borderStyle: "none",
    outline: "none",
    appearance: "none",
    color: BLACK,
    position: "relative",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    ":required:invalid": {
      color: MID_GREY,
    },
    ":focus": {
      color: BLACK,
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
  },
});

export default CountryField;
