// @flow
import * as React from "react";
import {
  CardElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {css, StyleSheet} from "aphrodite";
import {useState} from "react";
import {useIntl} from "gatsby-plugin-react-intl";
import donateImg from "../assets/checkout.png";
import {fontStandardMedium} from "../styles/typography";
import {parseNumber, formatNumberStringToDollar} from "../utils/numberUtils";
import {
  BLACK,
  GRAY,
  MID_GREY,
  LIGHT_GREY,
  RED,
  TEXT_BLACK,
  WHITE,
  SEAFOAM_5,
} from "../styles/colors";
import {BREAKPOINTS} from "../styles/sizes";

import Checkbox from "./Checkbox";
import CountryField from "./DonationForm/CountryField";
import ErrorMessage from "./DonationForm/ErrorMessage";

import Field from "./DonationForm/Field";
import PaymentSubmitButton from "./DonationForm/PaymentSubmitButton";
import Recaptcha from "react-google-recaptcha";

const isValidEmail = require("../utils/validation-helper").isValidEmail;
const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
const FORM_NAME = "org_donation";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: GRAY,
      color: BLACK,
      fontWeight: 500,
      fontSize: "16px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: MID_GREY,
      },
    },
    invalid: {
      iconColor: RED,
      color: RED,
    },
  },
};

type cardEventType = {
  brand: string,
  complete: boolean,
  elementType: string,
  empty: boolean,
  error: any,
  value: any,
};

function DonationForm(): React.Node {
  const intl = useIntl();

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentSuccessStatus, setPaymentSuccessStatus] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [donationAmount, setDonationAmount] = useState("");
  const [organization, setOrganization] = useState("");
  const [paymentButtonId, setPaymentButtonId] = useState(0);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [recaptcha, setRecaptcha] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
    address: {
      city: "",
      country: "",
      line1: "",
      postal_code: "",
      state: "",
    },
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  React.useEffect(() => {
    if (stripe && !paymentRequest) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Flexport.org Fund donation",
          amount: 1 * 100, // Initialize with 1 dollar to get button to show up
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
          setPaymentButtonId(paymentButtonId + 1);
        }
      });
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod");
      }
    };
  }, [stripe, paymentRequest, setPaymentRequest, donationAmount]);

  const runValidations = () => {
    if (
      !validateDonationAmount(donationAmount) ||
      !validateEmail(billingDetails.email) ||
      !recaptcha
    ) {
      return false;
    }

    const {address} = billingDetails;

    if (
      !(address.city && address.country && address.postal_code && address.state)
    ) {
      setError({
        message: intl.formatMessage({id: "donate_now_form_error_address"}),
      });
      return false;
    }
    if (!termsAccepted) {
      setError({
        message: intl.formatMessage({id: "donate_now_form_error_terms"}),
      });
      return false;
    }
    return true;
  };

  const validateDonationAmount = (amount: string) => {
    const re = /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/;
    const match = amount.match(re);
    if (match == null) {
      setError({
        message: intl.formatMessage({id: "donate_now_form_error_parse"}),
      });
      return false;
    }
    const parsed = parseNumber(amount);
    if (parsed < 1) {
      setError({
        message: intl.formatMessage({id: "donate_now_form_error_amount"}),
      });
      return false;
    }
    setError(null);
    return true;
  };

  const validateEmail = (email: string) => {
    if (!isValidEmail(email)) {
      setEmailError({
        message: intl.formatMessage({
          id: "donate_now_form_error_email_invalid",
        }),
      });
      return false;
    }
    setEmailError(null);
    return true;
  };

  const handlePaymentMethod = async ev => {
    setProcessing(true);

    if (!runValidations()) {
      setProcessing(false);
      return;
    }

    let captchaCheck = false;
    await fetch("/", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: new URLSearchParams({
        "g-recaptcha-response": recaptcha || "", // already validated as not null
        "form-name": FORM_NAME,
      }).toString(),
    }).then(() => {
      captchaCheck = true;
    });

    if (!captchaCheck) {
      // do not do anything if captcha is not validated
      setProcessing(false);
      return;
    }

    const baseUrl = window?.location?.origin;
    const netlifyFunctionURL = baseUrl
      ? `${baseUrl}/.netlify/functions`
      : ".netlify/functions";

    const response = await fetch(`${netlifyFunctionURL}/stripe-payment`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        amount: parseNumber(donationAmount) * 100,
        customerEmail: billingDetails.email,
      }),
    });
    const {client_secret: clientSecret} = await response.json();

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {paymentIntent, error: confirmError} =
      await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );

    // kick off background job, that will listen for payment confirmation
    await fetch(`${netlifyFunctionURL}/stripe-payment-confirm-background`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        paymentIntentId: paymentIntent.id,
        donationAmount,
        customerEmail: billingDetails.email,
        customerAddress: billingDetails.address,
        customerName: billingDetails.name,
        customerOrganization: organization,
      }),
    });

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error: paymentError} = await stripe.confirmCardPayment(
          clientSecret
        );
        if (paymentError) {
          // The payment failed -- ask your customer for a new payment method.
          setError(paymentError);
        } else {
          // The payment has succeeded.
          setPaymentSuccessStatus(true);
        }
      } else {
        // The payment has succeeded.
        setPaymentSuccessStatus(true);
      }
    }
    setProcessing(false);
    if (paymentMethodId !== ev.paymentMethod.id) {
      setPaymentMethodId(ev.paymentMethod.id);
    }
  };

  const handleCCSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!runValidations()) {
      setProcessing(false);
      return;
    }

    if (!cardComplete) {
      setError({
        message: intl.formatMessage({id: "donate_now_form_error_card"}),
      });
      return;
    }

    setProcessing(true);

    const baseUrl = window?.location?.origin;
    const netlifyFunctionURL = baseUrl
      ? `${baseUrl}/.netlify/functions`
      : ".netlify/functions";

    let captchaCheck = false;
    await fetch("/", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: new URLSearchParams({
        "g-recaptcha-response": recaptcha || "", // already validated as not null
        "form-name": FORM_NAME,
      }).toString(),
    }).then(() => {
      captchaCheck = true;
    });

    if (!captchaCheck) {
      // do not do anything if captcha is not validated
      setProcessing(false);
      return;
    }

    const response = await fetch(`${netlifyFunctionURL}/stripe-payment`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        amount: parseNumber(donationAmount) * 100,
        customerEmail: billingDetails.email,
      }),
    });
    const {client_secret: clientSecret, id: paymentIntentId} =
      await response.json();

    // kick off background job, that will listen for payment confirmation
    await fetch(`${netlifyFunctionURL}/stripe-payment-confirm-background`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        paymentIntentId,
        donationAmount,
        customerEmail: billingDetails.email,
        customerAddress: billingDetails.address,
        customerName: billingDetails.name,
        customerOrganization: organization,
      }),
    });

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    });

    if (result.error) {
      setError(result.error);
    } else if (result.paymentIntent.status === "succeeded") {
      setPaymentSuccessStatus(true);
    }

    setProcessing(false);
  };

  return paymentSuccessStatus ? (
    <>
      <img
        className={css(styles.img)}
        src={donateImg}
        alt="Flexport.org Fund Donation"
      />
      <h1 className={css(styles.title)}>
        {intl.formatMessage({id: "donate_now_success"})}
      </h1>
      <div
        className={`${css(styles.successDetails)} dangerouslySetContentStyle`}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "donate_now_success_details"}),
        }}
      />
      <div
        className={`${css(styles.successDetails)} dangerouslySetContentStyle`}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "donate_now_success_details2"}),
        }}
      />
    </>
  ) : (
    <>
      <form
        className={css(styles.form)}
        name={FORM_NAME}
        onSubmit={handleCCSubmit}
        method="post"
        data-netlify="true"
        data-netlify-recaptcha="true"
      >
        <h3 className={css(styles.formSectionTitle)}>
          {intl.formatMessage({id: "donate_now_form_section_amount"})}
        </h3>
        <input type="hidden" name="form-name" value={FORM_NAME} />
        <fieldset className={css(styles.formGroup)}>
          <div className={css(styles.formRow)}>
            <Field
              label="$"
              id="amount"
              type="text"
              placeholder={intl.formatMessage({
                id: "donate_now_form_section_amount_placeholder",
              })}
              required
              labelStyle={styles.amountLabel}
              value={donationAmount}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                validateDonationAmount(e.target.value);
                setDonationAmount(e.target.value);
              }}
            />
          </div>
        </fieldset>

        <h3 className={css(styles.formSectionTitle)}>
          {intl.formatMessage({id: "donate_now_form_section_amount"})}
        </h3>
        <fieldset className={css(styles.formGroup)}>
          <div className={css(styles.formRow)}>
            <Field
              label={intl.formatMessage({id: "donate_now_form_name"})}
              id="name"
              type="text"
              placeholder="Jane Doe"
              required
              autoComplete="name"
              value={billingDetails.name}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                setBillingDetails({...billingDetails, name: e.target.value});
              }}
            />
          </div>
          <div className={css(styles.formRow)}>
            <Field
              label={intl.formatMessage({id: "donate_now_form_organization"})}
              id="organization"
              type="text"
              placeholder={`Acme Inc. (${intl.formatMessage({
                id: "donate_now_form_optional",
              })})`}
              autoComplete="organization"
              value={organization}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) =>
                setOrganization(e.target.value)
              }
            />
          </div>
          <div className={css(styles.formRow)}>
            <Field
              label={intl.formatMessage({id: "donate_now_form_email"})}
              id="email"
              type="email"
              placeholder="janedoe@gmail.com"
              required
              autoComplete="email"
              value={billingDetails.email}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                validateEmail(e.target.value);
                setBillingDetails({...billingDetails, email: e.target.value});
              }}
            />
          </div>
          <div className={css(styles.formRow)}>
            <Field
              label={intl.formatMessage({id: "donate_now_form_phone"})}
              id="phone"
              type="tel"
              placeholder="(941) 555-0123"
              required
              autoComplete="tel"
              value={billingDetails.phone}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                setBillingDetails({...billingDetails, phone: e.target.value});
              }}
            />
          </div>
          <div className={css(styles.formRow)}>
            <Field
              label={intl.formatMessage({id: "donate_now_form_address"})}
              id="address"
              type="text"
              placeholder="123 Market street"
              required
              autoComplete="address"
              value={billingDetails.address.line1}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                setBillingDetails({
                  ...billingDetails,
                  address: {...billingDetails.address, line1: e.target.value},
                });
              }}
            />
          </div>
          <div className={css(styles.formRow, styles.multiFieldRow)}>
            <div className={css(styles.mobileOnlyRow)}>
              <Field
                label={intl.formatMessage({id: "donate_now_form_city"})}
                id="city"
                type="text"
                placeholder="San Francisco"
                required
                autoComplete="address-level2"
                value={billingDetails.address.city}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                  setBillingDetails({
                    ...billingDetails,
                    address: {...billingDetails.address, city: e.target.value},
                  });
                }}
                inputStyle={styles.medium}
              />
            </div>
            <div className={css(styles.mobileOnlyRow)}>
              <Field
                label={intl.formatMessage({id: "donate_now_form_state"})}
                id="state"
                type="text"
                placeholder="CA"
                required
                autoComplete="address-level1"
                value={billingDetails.address.state}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                  setBillingDetails({
                    ...billingDetails,
                    address: {...billingDetails.address, state: e.target.value},
                  });
                }}
                labelStyle={styles.xSmall}
                inputStyle={styles.xSmall}
              />
            </div>
            <div className={css(styles.mobileOnlyRow)}>
              <Field
                label={intl.formatMessage({id: "donate_now_form_zip"})}
                id="zip"
                type="text"
                placeholder="94103"
                required
                autoComplete="postal-code"
                value={billingDetails.address.postal_code}
                onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                  setBillingDetails({
                    ...billingDetails,
                    address: {
                      ...billingDetails.address,
                      postal_code: e.target.value,
                    },
                  });
                }}
                labelStyle={styles.xSmall}
                inputStyle={styles.xSmall}
              />
            </div>
          </div>
          <div className={css(styles.formRow)}>
            <CountryField
              value={billingDetails.address.country}
              onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                setBillingDetails({
                  ...billingDetails,
                  address: {...billingDetails.address, country: e.target.value},
                });
              }}
            />
          </div>
        </fieldset>
        {error ? (
          <ErrorMessage>{error.message}</ErrorMessage>
        ) : emailError ? (
          <ErrorMessage>{emailError.message}</ErrorMessage>
        ) : (
          donationAmount && (
            <div className={css(styles.contributionMessage)}>
              {`${intl.formatMessage({
                id: "donate_now_form_contribute",
              })}: ${formatNumberStringToDollar(donationAmount)}`}
            </div>
          )
        )}
        <div className={css(styles.terms)}>
          <Checkbox
            name="termsAccepted"
            active={termsAccepted}
            onClick={() => {
              setTermsAccepted(!termsAccepted);
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({id: "donate_now_terms"}),
            }}
          />
        </div>
        <div className={css(styles.recaptcha)}>
          <Recaptcha
            sitekey={RECAPTCHA_KEY}
            language={intl.locale}
            onChange={value => setRecaptcha(value)}
          />
        </div>
        <fieldset className={css(styles.formGroup, styles.cardElement)}>
          <CardElement
            options={CARD_OPTIONS}
            onChange={(e: cardEventType) => {
              if (e.error) {
                const cardError = e.error.message
                  ? e.error
                  : {message: e.error};
                setError(cardError);
              } else {
                setError(null);
              }
              setCardComplete(e.complete);
            }}
          />
        </fieldset>
        <PaymentSubmitButton
          processing={processing}
          error={error || emailError}
          disabled={!stripe || error !== null || emailError !== null}
        >
          {intl.formatMessage({id: "donate_now_form_donate_now"})}
        </PaymentSubmitButton>
        {paymentRequest && (
          <>
            <div className={css(styles.paymentSeparator)}>
              <p className={css(styles.separatorMessage)}>
                {intl.formatMessage({id: "donate_now_form_or"})}
              </p>
            </div>
            <div className={css(styles.paymentRequestButton)}>
              <PaymentRequestButtonElement
                id={paymentButtonId}
                options={{
                  style: {paymentRequestButton: {type: "donate"}},
                  paymentRequest,
                }}
                onClick={event => {
                  if (!runValidations()) {
                    event.preventDefault();
                  } else {
                    paymentRequest.update({
                      total: {
                        label: "Flexport.org Fund donation",
                        amount: parseNumber(donationAmount) * 100,
                      },
                    });
                    paymentRequest.on("paymentmethod", handlePaymentMethod);
                  }
                }}
              />
            </div>
          </>
        )}
        <div
          className={`dangerouslySetContentStyle ${css(styles.otherPayments)}`}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: "donate_now_form_other_payments"}),
          }}
        />
      </form>
    </>
  );
}
const fadeKeyframes = {
  from: {
    opacity: 0,
    transform: "scale3D(0.95, 0.95, 0.95)",
  },
  to: {
    opacity: 1,
    transform: "scale3D(1, 1, 1)",
  },
};

const styles = StyleSheet.create({
  form: {
    backgroundColor: WHITE,
    width: "100%",

    [BREAKPOINTS.DESKTOP]: {
      width: "460px",
    },
  },
  img: {
    display: "none",

    [BREAKPOINTS.DESKTOP]: {
      display: "block",
      width: "125px",
    },
  },
  title: {
    fontFamily: [fontStandardMedium],
    marginTop: "0px",
    fontWeight: 900,
    textAlign: "center",
    [BREAKPOINTS.DESKTOP]: {
      margin: "15px 0px 10px 0px",
    },
  },
  subtitle: {
    textAlign: "center",
    color: GRAY,
    fontSize: "12px",
    marginBottom: "25px",
    width: "unset",

    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  formSectionTitle: {
    display: "none",
    fontSize: "14px",
    fontFamily: [fontStandardMedium],
    margin: "25px 0px 10px 0px",
    ":first-of-type": {
      marginTop: "10px",
    },
    padding: "0px",
    [BREAKPOINTS.DESKTOP]: {
      paddingLeft: "16px",
    },
  },
  formGroup: {
    margin: "20px 0px",
    padding: "0",
    border: `1px solid ${LIGHT_GREY}`,
    backgroundColor: WHITE,
    willChange: "opacity, transform",
    borderRadius: "4px",
    [BREAKPOINTS.DESKTOP]: {
      margin: "20px 15px",
    },
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    borderTop: `1px solid ${LIGHT_GREY}`,
    ":first-child": {
      borderTop: "none",
    },
  },
  multiFieldRow: {
    display: "flex",
    flexDirection: "column",
    borderTop: "none",
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
    },
  },
  mobileOnlyRow: {
    width: "100%",
    borderTop: `1px solid ${LIGHT_GREY}`,
    [BREAKPOINTS.DESKTOP]: {
      borderTop: "none",
    },
  },
  amountLabel: {
    minWidth: "20px",
    width: "20px",
  },
  cardElement: {
    padding: "10px",
    marginTop: "25px",
  },
  terms: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "12px",
    lineHeight: "17px",
    color: TEXT_BLACK,
    [BREAKPOINTS.DESKTOP]: {
      padding: "0px 15px 15px 15px",
    },
  },
  recaptcha: {
    padding: "15px 0px",
    display: "flex",
    alignItems: "flex-start",
    [BREAKPOINTS.DESKTOP]: {
      padding: "0px 15px",
    },
  },
  otherPayments: {
    padding: "15px 0px 0px",
    fontSize: "12px",
    whiteSpace: "break-spaces",
    lineHeight: "12px",
    color: TEXT_BLACK,
    [BREAKPOINTS.DESKTOP]: {
      padding: "15px 15px 0px 15px",
    },

    a: {
      color: SEAFOAM_5,
    },
  },
  medium: {
    minWidth: "70px",

    [BREAKPOINTS.DESKTOP]: {
      minWidth: "120px",
    },
  },
  xSmall: {
    minWidth: "70px",

    [BREAKPOINTS.DESKTOP]: {
      minWidth: "50px",
    },
  },
  successDetails: {
    textAlign: "center",
    marginTop: "45px",
    color: TEXT_BLACK,
    fontSize: "14px",
    lineHeight: "20px",
  },
  actionButton: {
    paddingTop: "45px",
  },
  contributionMessage: {
    color: SEAFOAM_5,
    display: "flex",
    justifyContent: "center",
    padding: "15px 15px 15px 15px",
    fontSize: "13px",
    boxSizing: "border-box",
    width: "100%",
    transform: "translateY(-10px)",
    animation: "300ms ease-out",
    animationName: [fadeKeyframes],
    animationFillMode: "forwards",
    willChange: "opacity, transform",
    whiteSpace: "break-spaces",

    marginTop: "0px",
    marginBottom: "-15px",

    [BREAKPOINTS.DESKTOP]: {
      margin: 0,
    },
  },
  separatorMessage: {
    zIndex: 1,
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "14px",
    marginBottom: 0,
    ":before": {
      content: "''",
      borderTop: `1px solid ${GRAY}`,
      height: "1px",
      width: "50%",
      marginLeft: "0px",
      [BREAKPOINTS.DESKTOP]: {
        margin: "0px 15px",
      },
    },
    ":after": {
      content: "''",
      borderTop: `1px solid ${GRAY}`,
      height: "1px",
      width: "50%",
      marginRight: "0px",

      [BREAKPOINTS.DESKTOP]: {
        margin: "0px 15px",
      },
    },
  },
  paymentSeparator: {
    display: "flex",
    justifyContent: "center",
  },
  paymentRequestButton: {
    margin: "0px",

    [BREAKPOINTS.DESKTOP]: {
      margin: "0px 15px",
    },
  },
});

export default DonationForm;
